import {
  GET_ALL_SEGMENTS_LOADING,
  GET_ALL_SEGMENTS_ERROR,
  GET_ALL_SEGMENTS_SUCCESS,
  DELETE_SEGMENT,
  // DELETE_SEGMENT_ERROR,
  ADD_NEW_SEGMENT,
  UPDATE_SEGMENT,
  RESET_ADD_SEGMENT,
  GET_SEGMENT_BY_ID,
  RESET_SEGMENT_DATA,
  DELETE_SEGMENT_RESET,
  RESET_ADD_UPDATE_SEGMENT,
  GET_SEGMENT_BY_CUSTOMER_LOADING,
  GET_SEGMENT_BY_CUSTOMER_SUCCESS,
  GET_SEGMENT_BY_CUSTOMER_ERROR,
  UPDATE_SEGMENT_BY_CUSTOMER_LOADING,
  UPDATE_SEGMENT_BY_CUSTOMER_SUCCESS,
  UPDATE_SEGMENT_CUSTOMER,
  GET_SEGMENT_BY_ID_ERROR
} from "./constants";

// **  Initial State
const initialState = {
  segmentList: [],
  selectedSegment: {},
  loading: false,
  segmentErr: false,
};

const segmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SEGMENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ALL_SEGMENTS_SUCCESS:
      return {
        ...state,
        segmentList: action.segmentData,
        loading: false,
      };
    case GET_ALL_SEGMENTS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case ADD_NEW_SEGMENT:
      return {
        ...state,
        success: true,
      };
      case UPDATE_SEGMENT_CUSTOMER:
        return {
          ...state,
          success: true,
        };
    case RESET_ADD_SEGMENT:
      return {
        ...state,
        success: false,
      };
    case DELETE_SEGMENT:
      return {
        ...state,
        segmentList: state.segmentList.filter((el) => el.id !== action.payload),
      };
    case GET_SEGMENT_BY_CUSTOMER_LOADING:
      return {
        ...state,
        segmentByCustomerLoading: true,
      };
    case GET_SEGMENT_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        segmentByCustomerLoading: false,
        segmentByCustomerdata: action.payload,
      };
    case GET_SEGMENT_BY_CUSTOMER_ERROR:
      return {
        ...state,
        segmentByCustomerLoading: false,
        segmentByCustomerError: action.payload,
      };

    case UPDATE_SEGMENT_BY_CUSTOMER_LOADING:
      return {
        ...state,
        updateSegmentByCustomerLoading: true,
      };
    case UPDATE_SEGMENT_BY_CUSTOMER_SUCCESS:
      return {
        ...state,
        updateSegmentByCustomerLoading: false,
        updateSuccess: true
      };


    case UPDATE_SEGMENT:
      return {
        ...state,
        // success: true,
      };

    case GET_SEGMENT_BY_ID:
      return {
        ...state,
        selectedSegment: action.segmentData,
        selectedSegmentError: false
      };

    case GET_SEGMENT_BY_ID_ERROR:{
      console.log("error")
      return {
        ...state,
        selectedSegment: null,
        selectedSegmentError: true
      }
    }

    case RESET_SEGMENT_DATA:
      return {
        ...state,
        selectedSegment: null,
        selectedSegmentError: false
      };

    default:
      return state;
  }
};

export default segmentReducer;
