import {
  SAVE_SMS_FORM_INFO,
  ADD_NEW_SMS_CAMPAIGN_LOAD,
  ADD_NEW_SMS_CAMPAIGN_SUCCESS,
  ADD_NEW_SMS_CAMPAIGN_ERROR,
  GET_ALL_SMS_CAMPAIGN,
  DELETE_SMS_CAMPAIGN,
  UPDATE_SMS_CAMPAIGN,
  SMS_COMPAIGN_TEST_LOADING,
  SMS_COMPAIGN_TEST_FAILURE,
  SMS_COMPAIGN_TEST_SUCCESS,
  SMS_COMPAIGN_ERROR,
  ADD_SMS_CAMPAIGN_RESET
} from "./constants";

// **  Initial State
const initialState = {
  formData: {},
  rawData: {},
  campaignList: [],
  loading: false,
  error: false,
  testLoading: false,
  testSuccess: true
};

const smsCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_SMS_CAMPAIGN:
      return {
        ...state,
        campaignList: [...action.campaignList],
      };
    case ADD_NEW_SMS_CAMPAIGN_LOAD:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ADD_NEW_SMS_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignList: [...state.campaignList, action.campaign],
      };
    case ADD_NEW_SMS_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.msg
      };

    case DELETE_SMS_CAMPAIGN:
      return {
        ...state,
        campaignList: state.campaignList.filter(
          (el) => el.id !== action.payload
        ),
      };
    case SAVE_SMS_FORM_INFO:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.formData,
        },
        rawData: {
          ...state.rawData,
          ...action.rawData,
        },
      };
    case UPDATE_SMS_CAMPAIGN: {
      return {
        ...state,
        campaignList: state.campaignList.map((ele) => {
          if (ele.id === action.campaign.id) {
            return { ...action.campaign };
          } else return ele;
        }),
      };
    }

    case SMS_COMPAIGN_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case SMS_COMPAIGN_TEST_LOADING:
      return {
        ...state,
        testSuccess: false,
        testLoading: true
      }

    case SMS_COMPAIGN_TEST_FAILURE:
      return {
        ...state,
        testLoading: false,
        testSuccess: false
      }

    case SMS_COMPAIGN_TEST_SUCCESS:
      return {
        ...state,
        testLoading: false,
        testSuccess: true
      }

    case ADD_SMS_CAMPAIGN_RESET:
      return {
        ...state,
        formData: {},
        rawData: {},
      }

    default:
      return state;
  }
};

export default smsCampaignReducer;
