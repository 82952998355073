import {
  SAVE_TEMPLATE,
  GET_ALL_TEMPLATES_SUCCESS,
  GET_ALL_TEMPLATES_LOADING,
  GET_SAVED_TEMPLATE,
  DELETE_TEMPLATE,
  ADD_NEW_TEMPLATE,
  RESET_SAVED_EMAIL_TEMPLATE,
  DUPLICATE_EMAIL_TEMPLATE_SUCCESS,
  TEST_TEMPLATE_SUCCESS,
} from "./constants";

// **  Initial State
const initialState = {
  prevData: null,
  templateList: [],
  export: null,
  currTemplateName: "",
};

const emailTemplateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_TEMPLATES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templateList: action.templates,
      };
    case DELETE_TEMPLATE:
      return {
        ...state,
        templateList: state.templateList.filter(
          (temp) => temp.id !== action.payload
        ),
      };
    case GET_SAVED_TEMPLATE:
      return {
        ...state,
        prevData: action.prevData,
        currTemplateName: action.name,
      };

    case RESET_SAVED_EMAIL_TEMPLATE:
      return {
        ...state,
        prevData: null,
        currTemplateName: null,
        duplicateSuccess: false,
      };
    case ADD_NEW_TEMPLATE:
      return {
        ...state,
        templateList: [...state.templateList, action.payload],
      };
    case SAVE_TEMPLATE: {
      return {
        ...state,
        prevData: action.savedData,
      };
    }
    case DUPLICATE_EMAIL_TEMPLATE_SUCCESS:
      return {
        ...state,
        duplicateSuccess: true,
      };
    case TEST_TEMPLATE_SUCCESS:
      return {
        ...state,
        testTemplateSuccess: true,
      };
    default:
      return state;
  }
};

export default emailTemplateReducer;
