export const GET_ALL_SEGMENTS_ERROR = "GET_ALL_SEGMENTS_ERROR"
export const GET_ALL_SEGMENTS_LOADING = "GET_ALL_SEGMENTS_LOADING"
export const GET_ALL_SEGMENTS_SUCCESS = "GET_ALL_SEGMENTS_SUCCESS"
export const DELETE_SEGMENT = "DELETE_SEGMENT"
export const DELETE_SEGMENT_ERROR = "DELETE_SEGMENT_ERROR"
export const ADD_NEW_SEGMENT = "ADD_NEW_SEGMENT"
export const UPDATE_SEGMENT = "UPDATE_SEGMENT"
export const UPDATE_SEGMENT_CUSTOMER = "UPDATE_SEGMENT_CUSTOMER"
export const RESET_ADD_SEGMENT = "RESET_ADD_SEGMENT"
export const GET_SEGMENT_BY_ID = "GET_SEGMENT_BY_ID"
export const GET_SEGMENT_BY_ID_ERROR = "GET_SEGMENT_BY_ID_ERROR"
export const RESET_SEGMENT_DATA = "RESET_SEGMENT_DATA"

export const GET_SEGMENT_BY_CUSTOMER_LOADING = "GET_SEGMENT_BY_CUSTOMER_LOADING"
export const GET_SEGMENT_BY_CUSTOMER_SUCCESS = "GET_SEGMENT_BY_CUSTOMER_SUCCESS"
export const GET_SEGMENT_BY_CUSTOMER_ERROR = "GET_SEGMENT_BY_CUSTOMER_ERROR"

export const UPDATE_SEGMENT_BY_CUSTOMER_LOADING = "UPDATE_SEGMENT_BY_CUSTOMER_LOADING"
export const UPDATE_SEGMENT_BY_CUSTOMER_SUCCESS = "UPDATE_SEGMENT_BY_CUSTOMER_SUCCESS"