
export const SAVE_PUSH_FORM_INFO = "SAVE_PUSH_FORM_INFO"
export const GET_ALL_PUSH_CAMPAIGN = "GET_ALL_PUSH_CAMPAIGN"
export const ADD_NEW_PUSH_CAMPAIGN_LOAD = "ADD_NEW_PUSH_CAMPAIGN_LOAD"
export const ADD_NEW_PUSH_CAMPAIGN_ERROR = "ADD_NEW_PUSH_CAMPAIGN_ERROR"
export const ADD_NEW_PUSH_CAMPAIGN_SUCCESS = "ADD_NEW_PUSH_CAMPAIGN_SUCCESS"
export const UPDATE_PUSH_CAMPAIGN = "UPDATE_PUSH_CAMPAIGN"
export const DELETE_PUSH_CAMPAIGN = "DELETE_PUSH_CAMPAIGN"
export const RESET_PUSH_CAMPAIGN = "RESET_PUSH_CAMPAIGN"
export const PUSH_COMPAIGN_ERROR = "PUSH_COMPAIGN_ERROR" 
export const PUSH_COMPAIGN_TEST_SUCCESS = "PUSH_COMPAIGN_TEST_SUCCESS" 
export const PUSH_COMPAIGN_TEST_FAILURE = "PUSH_COMPAIGN_TEST_FAILURE" 
export const PUSH_COMPAIGN_TEST_LOADING = "PUSH_COMPAIGN_TEST_LOADING" 