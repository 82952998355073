import {
  USERS_LIST_FAILED,
  USERS_LIST_LOADING,
  USERS_LIST_SUCCESS,
  ADD_CONTACT_FAILED,
  ADD_CONTACT_LOADING,
  ADD_CONTACT_SUCCESS,
  ADD_CONTACT_RESET
} from "./constants";

const initialState = {
  lists: [],
  count: 0,
  error: "",
};

export const usersListReducer = (state = initialState, action) => {
  switch (action.type) {
    case USERS_LIST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case USERS_LIST_SUCCESS:
      return {
        loading: false,
        lists: action.payload,
        count: action.count,
      };
    case USERS_LIST_FAILED:
      return {
        loading: false,
        error: action.payload,
        csvError: action.payload,
      };
    case ADD_CONTACT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADD_CONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        data: action.payload,
      };
    case ADD_CONTACT_FAILED:
      return {
        loading: false,
        errMsg: action.payload,
      };
    case ADD_CONTACT_RESET:
      return state
    default:
      return state;
  }
};
