import {
  GET_ALL_ROLES_LOADING,
  GET_ALL_ROLES_SUCCESS,
  GET_ALL_MODULES_LOADING,
  GET_ALL_MODULES_SUCCESS,
  ADD_NEW_ROLE,
  DELETE_USER_ROLE,
  UPDATE_USER_ROLE,
  GET_USER_ROLE,
  RESET_USER_ROLE
} from "./constants";

// **  Initial State
const initialState = {
  moduleList: [],
  userRoles: [],
  currentRole: {}
};

const userRoleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_MODULES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        moduleList: action.modulesData,
      };

    case GET_ALL_ROLES_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_USER_ROLE:
      return {
        ...state,
        currentRole: action.role,
      };

    case GET_ALL_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        userRoles: action.rolesData,
      };

    case ADD_NEW_ROLE:
      return {
        ...state,
        userRoles: [...state.userRoles, action.payload],
      };

    case RESET_USER_ROLE:
      return {
        ...state,
        currentRole: {},
      }

    case UPDATE_USER_ROLE: {
      return {
        ...state,
        userRoles: state.userRoles.map((ele) => {
          if (ele.id === action.payload.id) {
            return { ...action.payload };
          } else return ele;
        }),
      };
    }
    case DELETE_USER_ROLE:{
      console.log("delete role", action.payload)
      return {
        ...state,
        userRoles: state.userRoles.filter((el) => el.id !== action.payload),
      };
    }

    //   case UPDATE_CREDENTIAL: {
    //     return {
    //       ...state,
    //       credentialsList: state.credentialsList.map(ele => {
    //         if (ele.id === action.payload.id) {
    //           return { ...action.payload };
    //         }
    //         else return ele;
    //       })
    //     }
    //   }
    default:
      return state;
  }
};

export default userRoleReducer;
