import {
  GET_ALL_INTEGRATION_LOADING,
  GET_ALL_INTEGRATION_SUCCESS,
  GET_ALL_SERVICES_LOADING,
  GET_ALL_SERVICES_SUCCESS,
  UPDATE_FORM_DATA,
  GET_META_DATA_TYPE,
  GET_META_DATA_VALUES,
  RESET_INTEGRATION_SERVICES,
} from "./constants";

// **  Initial State
const initialState = {
  channels: [],
  services: [],
  formInput: [],
  formValues: [],
  form: {},
};

const integrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_INTEGRATION_LOADING:
      return {
        ...state,
        loading: true,
      };

    case GET_ALL_INTEGRATION_SUCCESS:
      return {
        ...state,
        loading: false,
        channels: action.payload,
      };

    case GET_ALL_SERVICES_LOADING:
      return {
        ...state,
        loadServices: true,
      };

    case GET_ALL_SERVICES_SUCCESS:
      return {
        ...state,
        loadServices: false,
        services: action.services,
        servicesMap: action.servicesMap,
      };

    case GET_META_DATA_TYPE:
      return {
        ...state,
        formInput: action.formInput,
      };

    case RESET_INTEGRATION_SERVICES:
      return {
        ...state,
        services: [],
      };

    case UPDATE_FORM_DATA:
      return {
        ...state,
        form: { ...action.data },
      };

    default:
      return state;
  }
};

export default integrationReducer;
