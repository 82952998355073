import {
  SAVE_PUSH_FORM_INFO,
  ADD_NEW_PUSH_CAMPAIGN_LOAD,
  ADD_NEW_PUSH_CAMPAIGN_SUCCESS,
  ADD_NEW_PUSH_CAMPAIGN_ERROR,
  GET_ALL_PUSH_CAMPAIGN,
  DELETE_PUSH_CAMPAIGN,
  UPDATE_PUSH_CAMPAIGN,
  PUSH_COMPAIGN_TEST_LOADING,
  PUSH_COMPAIGN_TEST_FAILURE,
  PUSH_COMPAIGN_TEST_SUCCESS,
  PUSH_COMPAIGN_ERROR,
} from "./constants";

// **  Initial State
const initialState = {
  formData: {},
  rawData: {},
  campaignList: [],
  loading: false,
  error: false,
  testLoading: false,
  testSuccess: true
};

const pushCampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ALL_PUSH_CAMPAIGN:
      return {
        ...state,
        campaignList: [...action.campaignList],
      };
    case ADD_NEW_PUSH_CAMPAIGN_LOAD:
      return {
        ...state,
        loading: true,
        error: false
      };
    case ADD_NEW_PUSH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        loading: false,
        campaignList: [...state.campaignList, action.campaign],
      };
    case ADD_NEW_PUSH_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.msg
      };

    case DELETE_PUSH_CAMPAIGN:
      return {
        ...state,
        campaignList: state.campaignList.filter(
          (el) => el.id !== action.payload
        ),
      };
    case SAVE_PUSH_FORM_INFO:
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.formData,
        },
        rawData: {
          ...state.rawData,
          ...action.rawData,
        },
      };
    case UPDATE_PUSH_CAMPAIGN: {
      return {
        ...state,
        campaignList: state.campaignList.map((ele) => {
          if (ele.id === action.campaign.id) {
            return { ...action.campaign };
          } else return ele;
        }),
      };
    }

    case PUSH_COMPAIGN_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case PUSH_COMPAIGN_TEST_LOADING:
      return {
        ...state,
        testSuccess: false,
        testLoading: true
      }

    case PUSH_COMPAIGN_TEST_FAILURE:
      return {
        ...state,
        testLoading: false,
        testSuccess: false
      }

    case PUSH_COMPAIGN_TEST_SUCCESS:
      return {
        ...state,
        testLoading: false,
        testSuccess: true
      }

    default:
      return state;
  }
};

export default pushCampaignReducer;
